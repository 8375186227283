.layout-header {
	background-color: #232528;
	box-shadow: 0 4px 4px rgba(0,0,0,.3);
	color: #fff;
}

.layout-header__logo {
	display: block;
	width: 185px;
	height: 26px;
	background: url(/assets/images/logo.png) 50% 50% no-repeat;
	background-size: contain;
}

.layout-header__inner {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
