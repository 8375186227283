.btn {
	display: inline-block;
	vertical-align: top;
	cursor: pointer;
	border: none;
	background-color: transparent;
	border-radius: 0;
	text-align: center;

	&.btn-default {
		color: #a6a6a6;
		transition: color .3s;
		text-transform: inherit;
	}

	&.btn-primary {
		text-transform: lowercase;
		font-size: 18px;
		font-weight: 400;
	}

	&:hover {
		color:#fff;
	}
}

a.btn-default,
.btn-default {
	color: #a6a6a6;
	transition: color .3s;
	font-size: 18px;

	.icon {
		width: 20px;
		height: 20px;
		fill:#a6a6a6;
		margin-right: 10px;
		transition: fill .3s;
	}

	&.active,
	&:hover {
		color: #fff;

		.icon {
			fill:#fff;
		}
	}
}

.btn-primary {
	.icon-next {
		width: 16px;
		height: 16px;
		margin-left: 10px;
		fill:#009cff;
	}

	&:hover {
		color: #009cff;
	}
}

.btn-light {
	color: #fff;

	&:hover {
		color: #fff;
	}
}

.btn-full {
	width: 100%;
}

.btn-info {
	.icon-info {
		width: 26px;
		height: 26px;
		fill:#fff;
	}
}

.btn-search {
	.icon-search {
		width: 26px;
		height: 26px;
		fill:#fff;
	}
}

button.btn-close,
a.btn-close {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	line-break: 1;
	padding: 10px;
	min-height: inherit;
	min-width: inherit;
	margin-left: auto;

	.icon-close {
		fill:#000;
		width: 20px;
		height: 20px;
		display: block;
	}
}
