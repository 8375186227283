.module-search {
	padding-top: 20px;
	padding-bottom: 20px;
}


.module-search {
	.grid-primary {
		width: auto;
	}
}

.grid-primary {
	margin-left: -25px;
	margin-right: -25px;
	width: auto;
}

.grid-primary__item {
	padding-left: 25px;
	padding-right: 25px;
}

.dialog-wrapper {
	overflow: hidden;
}


.paper-inner {
	padding: 15px;
}

.grid-default {
	margin: 0 -10px;
	display: flex;
}

.grid-default__item {
	width: 100%;
	padding: 0 10px;
	margin-bottom: 15px;

	&.x1 {

	}

	&.x4 {
		width: 33.333%;
	}

	&.x6 {
		width: 50%;
	}

	&.x12 {
		width: 100%;
	}
}

.table-default {
	thead{
		th {
			font-size: 16px;
			color: #232528;
			font-weight: 400;

			> span {
				display: flex;
			}
		}
	}

	tbody {
		td {
			color: #737373;
			font-size: 16px;
		}
	}

	th,
	td {
		padding: 4px 25px 4px 25px
	}
}

.specifications {
	display: flex;
}

.specifications__preview {
	padding-right: 20px;
	flex: 0 0 120px;
}

.specifications__image {
	vertical-align: top;
	max-width: 100%;
	height: auto;
}

.specifications__panel {
	flex: 1;

	.grid-default {
		margin-bottom: -20px;
	}
}

.list-info {
	.list-info__item {
		&:last-child {
			margin-bottom: 0;
		}
	}
}

.list-info__item {
	display: flex;
	font-size: 20px;
	color: #1A1A1A;
	margin-bottom: 10px;
}

.list-info__label {
	flex: 0 0 150px;
	padding-right: 10px;
}

.list-info__body {
	color: #737373;
	flex: 1;
}

.paper-title {
	font-size: 23px;
	color: #1A1A1A;
}

.paper-head {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	z-index: 2;
	margin-bottom: 20px;

	.btn-close {
		margin: -5px 0 -5px auto;
	}

	.btn-primary {
		margin: -8px 0 -8px auto;
	}
}

.line-graph {
	.line-graph__row {
		&:last-child {
			margin-bottom: 0;
		}
	}
}

.line-graph__row {
	display: flex;
	margin-bottom: 8px;
	height: 36px;
	flex: 1;
	width: 100%;
	position: relative;
}

.line-graph__item {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	color: #232528;
	text-align: center;
	background-color: #e5e5e5;
	border: 1px solid #bdbdbd;
	box-shadow: 0 1px 3px rgba(0,0,0,.1);
}

.line-graph__number {
	position: absolute;
	top: 50%;
	left: 0;
	color: #232528;
	font-size: 16px;
	z-index: 2;
	transform: translateY(-50%);
	margin: 0 0 0 -25px;
}

.line-graph__cell {
	padding: 10px 15px;
	font-size: 11px;
	font-weight: 700;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	overflow: hidden;

	&.cell-green {
		background-color: #caddc8;
		width: 50px;
	}

	&.cell-lg {
		width: 100%;
	}

	&.cell-red {
		background-color: #e5d3d3;
		width: 50px;
	}

	.icon-round {
		box-shadow: 0 2px 3px rgba(0,0,0,.2);
		margin: 0 2px 0 2px;
	}

	.icon-round_left {
		position: absolute;
		top: 0;
		left: 0;
		margin: 1px 0 0 1px;
	}

	.icon-round_right {
		position: absolute;
		top: 0;
		right: 0;
		margin: 1px 1px 0 0;
	}

	.icon-round_top {
		position: relative;
		top: -18px;
	}
}

.nav-label {
	display: flex;
	margin: 0 -15px 15px;
}

.nav-label__item {
	margin: 0 15px;

	.btn {
		color: #737373;
		font-size: 18px;
		font-weight: 400;
		text-transform: initial;
		@include transition(color .3s);

		&.active {
			background-color: #e5e5e5;
			box-shadow: 0 2px 2px rgba(0,0,0,.3);
			

			&.btn-red {
				.icon {
					border-color: #d80003;
					color: #d80003;
				}
			}

			&.btn-yellow {
				.icon {
					border-color: #ff9933;
					color: #ff9933;
				}
			}

			&.btn-purple {
				.icon {
					border-color: #bf17a0;
					color: #bf17a0;
				}
			}

			&.btn-blue {
				.icon {
					border-color: #006BE6;
					color: #006BE6;
				}
			}

			&:hover {
				color: #737373;
			}

		}
	}

	.btn-red {
		.icon {
			border-color: #d80003;
			color: #d80003;
		}

		&:hover {
			color: #d80003;
		}
	}

	.btn-purple {
		.icon {
			border-color: #bf17a0;
			color: #bf17a0;
		}

		&:hover {
			color: #bf17a0;
		}
	}

	.btn-yellow {
		.icon {
			border-color: #ff9933;
			color: #ff9933;
		}

		&:hover {
			color: #ff9933;
		}
	}

	.btn-blue {
		.icon {
			border-color: #006BE6;
			color: #006BE6;
		}

		&:hover {
			color: #006BE6;
		}
	}

	.icon-round {
		margin-right: 10px;
	}
	
}

.icon-round {
	width: 36px;
	height: 36px;
	border-width: 2px;
	border-style: solid;
	vertical-align: top;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 20px;
	font-weight: 400;
	background-color: #fff;

	&.icon-red {
		border-color: #d80003;
		color: #d80003;
	}

	&.icon-yellow {
		border-color: #ff9933;
		color: #ff9933;
	}

	&.icon-blue {
		border-color: #006BE6;
		color: #006BE6;
	}

	&.icon-purple {
		border-color: #bf17a0;
		color: #bf17a0;
	}
}

div.panel-default {
	background-color: #fafafa;

	span {
		font-size: 20px;
	}
}

p.panel-default__title {
	font-size: 22px;
	color: #232528;
}

.section-lasr__grid {
	display: flex;
}

.section-lasr__aside {
	flex: 0 0 130px;
}

.section-lasr__panel {
	width: 100%;
}


.section-lasr__footer,
.section-lasr__header {
	padding-left: 130px;
	padding-right: 130px;
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
}

.section-lasr__label {
	color: #737373;
	font-size: 20px;
	line-height: 1;
}

.section-lasr__footer {
	padding-top: 20px;
}

.section-lasr__header {
	padding-bottom: 20px;
	margin-top: -40px;
}

.section-lasr__center {
	text-align: center;
	padding-left: 20px;
	padding-right: 20px;
}

.section-lasr__description {
	color: #737373;
	font-size: 18px;
	margin-top: 5px;
	line-height: 1;

	p {
		margin: 0;
	}
}

.section-lasr__directions {
	font-size: 14px;
	color: #232528;
	text-transform: uppercase;
	text-align: center;
	position: relative;
	

	&:before {
		content:"";
		width: 0; 
		height: 0; 
		border-left: 8px solid transparent;
		border-right: 8px solid transparent;
		border-bottom: 5px solid #232528;
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		margin: -8px 0 0 0;
	}
}

.section-lasr__directions {
	+ .section-lasr__label {
		margin-top: 5px;
	}
}


.section-lasr__panel {
	.line-graph__cell {
		font-size: 16px;
	}
}

div.datepicker-default {
	margin: -5px 0;

	input {
		color: #737373;
		font-size: 20px;
	}
}

@media (max-width: 1200px) {

	.specifications__preview {
		padding-right: 30px;
	}

	.grid-primary {
		margin-left: -15px;
		margin-right: -15px;
	}

	.grid-primary__item {
		padding-left: 15px;
		padding-right: 15px;
	}

	.table-default {
		thead {
			th {
				min-width: 120px;
			}
		}
		th,
		td {
			font-size: 14px;
			padding: 4px 10px 4px 10px
		}
	}
}

.boxes {
	padding-top: 20px;
	display: flex;
	width: 100%;
	flex-direction: column;
}

.boxes__line {
	width: 100%;
}

.box {
	border: 1px solid #bdbdbd;
	background-color: #d8d8d8;
	position: relative;
}

.box-s4 {
	width: 38px;
	height: 38px;
	text-align: center;
	line-height: 36px;
	margin-left: 25px;
}

.box-g3 {
	width: 38px;
	height: 11px;
	margin-left: 17px;
	margin-top: 50px;

	.box__label {
		position: absolute;
		left: 0;
		right: 0;
		text-align: center;
		bottom: 100%;
		padding-bottom: 5px;
	}
}

.box-g2 {
	width: 11px;
	height: 38px;
	margin-left: 72px;
	margin-top: 25px;

	.box__label {
		position: absolute;
		height: 100%;
		line-height: 36px;
		right: 100%;
		padding-right: 5px;
	}
}

.box-g1 {
	width: 38px;
	height: 11px;
	margin-left: 17px;
	margin-top: 30px;

	.box__label {
		position: absolute;
		left: 0;
		right: 0;
		top: 100%;
		text-align: center;
		padding-top: 5px;
	}
}

.box-s2 {
	width: 38px;
	height: 38px;
	margin-left: 25px;
	margin-top: 40px;
	text-align: center;
	line-height: 36px;
}

.box__label {
	color: #232528;
	font-size: 16px;
	font-weight: 400;
}

.box-s3 {
	width: 38px;
	height: 38px;
	text-align: center;
	line-height: 36px;
	margin-right: 25px;
}

.box-g6 {
	width: 38px;
	height: 11px;
	margin-right: 17px;
	margin-top: 50px;

	.box__label {
		position: absolute;
		left: 0;
		right: 0;
		text-align: center;
		bottom: 100%;
		padding-bottom: 5px;
	}
}

.box-g5 {
	width: 11px;
	height: 38px;
	margin-right: 72px;
	margin-top: 25px;

	.box__label {
		position: absolute;
		height: 100%;
		line-height: 36px;
		left: 100%;
		padding-left: 5px;
	}
}

.box-g4 {
	width: 38px;
	height: 11px;
	margin-right: 17px;
	margin-top: 30px;

	.box__label {
		position: absolute;
		left: 0;
		right: 0;
		top: 100%;
		text-align: center;
		padding-top: 5px;
	}
}

.box-s1 {
	width: 38px;
	height: 38px;
	margin-right: 25px;
	margin-top: 40px;
	text-align: center;
	line-height: 36px;
}

.boxes_right {

	.boxes__line {
		justify-content: flex-end;
	}
}


.boxes__line {
	display: flex;
}

@media (max-width: 1140px) {
	// .line-graph__cell {
	// 	padding: 10px 5px;
	// }
}

@media (max-width: 1023px) {

	.specifications {
		padding-bottom: 10px;
	}

	.section-lasr__aside {
		display: none;
	}
	
	.section-lasr__grid {
		padding-left: 30px;
	}


	.section-lasr__header,
	.section-lasr__footer {
		padding-left: 30px;
		padding-right: 0;
	}

	.section-lasr__header {
		margin-top: 0;
	}

	.grid-primary {
		margin-left: -10px;
		margin-right: -10px;
	}

	.grid-primary__item {
		padding-left: 10px;
		padding-right: 10px;
	}

	.module-search {
		padding-top: 10px;
		padding-bottom: 10px;
	}

	.grid-default {
		flex-wrap: wrap;
	}

	.grid-default__item {
		&.x1,
		&.x2,
		&.x3,
		&.x4,
		&.x5,
		&.x6 {
			width: 100%;
		}
	}

	.specifications {
		align-items: end;
	}

	.line-graph__cell {
		font-size: 14px;
	}

}


.react-vis-magic-css-import-rule {
	display: inherit;
  }
  
  .rv-treemap {
	font-size: 12px;
	position: relative;
  }
  
  .rv-treemap__leaf {
	overflow: hidden;
	position: absolute;
  }
  
  .rv-treemap__leaf--circle {
	align-items: center;
	border-radius: 100%;
	display: flex;
	justify-content: center;
  }
  
  .rv-treemap__leaf__content {
	overflow: hidden;
	padding: 10px;
	text-overflow: ellipsis;
  }
  
  .rv-xy-plot {
	color: #c3c3c3;
	position: relative;
  }
  
  .rv-xy-plot canvas {
	pointer-events: none;
  }
  
  .rv-xy-plot .rv-xy-canvas {
	pointer-events: none;
	position: absolute;
  }
  
  .rv-xy-plot__inner {
	display: block;
  }
  
  .rv-xy-plot__axis__line {
	fill: none;
	stroke-width: 2px;
	stroke: #e6e6e9;
  }
  
  .rv-xy-plot__axis__tick__line {
	stroke: #e6e6e9;
  }
  
  .rv-xy-plot__axis__tick__text {
	fill: #6b6b76;
	font-size: 11px;
  }
  
  .rv-xy-plot__axis__title text {
	fill: #6b6b76;
	font-size: 11px;
  }
  
  .rv-xy-plot__grid-lines__line {
	stroke: #e6e6e9;
  }
  
  .rv-xy-plot__circular-grid-lines__line {
	fill-opacity: 0;
	stroke: #e6e6e9;
  }
  
  .rv-xy-plot__circular-grid-lines__line {
	fill-opacity: 0;
	stroke: #e6e6e9;
  }
  
  .rv-xy-plot__series,
  .rv-xy-plot__series path {
	pointer-events: all;
  }
  
  .rv-xy-plot__series--line {
	fill: none;
	stroke: #000;
	stroke-width: 2px;
  }
  
  .rv-crosshair {
	position: absolute;
	font-size: 11px;
	pointer-events: none;
  }
  
  .rv-crosshair__line {
	background: #47d3d9;
	width: 1px;
  }
  
  .rv-crosshair__inner {
	position: absolute;
	text-align: left;
	top: 0;
  }
  
  .rv-crosshair__inner__content {
	border-radius: 4px;
	background: #3a3a48;
	color: #fff;
	font-size: 12px;
	padding: 7px 10px;
	box-shadow: 0 2px 4px rgba(0,0,0,0.5);
  }
  
  .rv-crosshair__inner--left {
	right: 4px;
  }
  
  .rv-crosshair__inner--right {
	left: 4px;
  }
  
  .rv-crosshair__title {
	font-weight: bold;
	white-space: nowrap;
  }
  
  .rv-crosshair__item {
	white-space: nowrap;
  }
  
  .rv-hint {
	position: absolute;
	pointer-events: none;
  }
  
  .rv-hint__content {
	border-radius: 4px;
	padding: 7px 10px;
	font-size: 12px;
	background: #3a3a48;
	box-shadow: 0 2px 4px rgba(0,0,0,0.5);
	color: #fff;
	text-align: left;
	white-space: nowrap;
  }
  
  .rv-discrete-color-legend {
	box-sizing: border-box;
	overflow-y: auto;
	font-size: 12px;
  }
  
  .rv-discrete-color-legend.horizontal {
	white-space: nowrap;
  }
  
  .rv-discrete-color-legend-item {
	color: #3a3a48;
	border-radius: 1px;
	padding: 9px 10px;
  }
  
  .rv-discrete-color-legend-item.horizontal {
	display: inline-block;
  }
  
  .rv-discrete-color-legend-item.horizontal .rv-discrete-color-legend-item__title {
	margin-left: 0;
	display: block;
  }
  
  .rv-discrete-color-legend-item__color {
	background: #dcdcdc;
	display: inline-block;
	height: 2px;
	vertical-align: middle;
	width: 14px;
  }
  
  .rv-discrete-color-legend-item__title {
	margin-left: 10px;
  }
  
  .rv-discrete-color-legend-item.disabled {
	color: #b8b8b8;
  }
  
  .rv-discrete-color-legend-item.clickable {
	cursor: pointer;
  }
  
  .rv-discrete-color-legend-item.clickable:hover {
	background: #f9f9f9;
  }
  
  .rv-search-wrapper {
	display: flex;
	flex-direction: column;
  }
  
  .rv-search-wrapper__form {
	flex: 0;
  }
  
  .rv-search-wrapper__form__input {
	width: 100%;
	color: #a6a6a5;
	border: 1px solid #e5e5e4;
	padding: 7px 10px;
	font-size: 12px;
	box-sizing: border-box;
	border-radius: 2px;
	margin: 0 0 9px;
	outline: 0;
  }
  
  .rv-search-wrapper__contents {
	flex: 1;
	overflow: auto;
  }
  
  .rv-continuous-color-legend {
	font-size: 12px;
  }
  
  .rv-continuous-color-legend .rv-gradient {
	height: 4px;
	border-radius: 2px;
	margin-bottom: 5px;
  }
  
  .rv-continuous-size-legend {
	font-size: 12px;
  }
  
  .rv-continuous-size-legend .rv-bubbles {
	text-align: justify;
	overflow: hidden;
	margin-bottom: 5px;
	width: 100%;
  }
  
  .rv-continuous-size-legend .rv-bubble {
	background: #d8d9dc;
	display: inline-block;
	vertical-align: bottom;
  }
  
  .rv-continuous-size-legend .rv-spacer {
	display: inline-block;
	font-size: 0;
	line-height: 0;
	width: 100%;
  }
  
  .rv-legend-titles {
	height: 16px;
	position: relative;
  }
  
  .rv-legend-titles__center,
  .rv-legend-titles__left,
  .rv-legend-titles__right {
	position: absolute;
	white-space: nowrap;
	overflow: hidden;
  }
  
  .rv-legend-titles__center {
	display: block;
	text-align: center;
	width: 100%;
  }
  
  .rv-legend-titles__right {
	right: 0;
  }
  
  .rv-radial-chart .rv-xy-plot__series--label {
	pointer-events: none;
  }

.graphics__panel {
	width: 100%;
	float: left;
	padding-right: 230px;
}

.graphics__nav {
	float: left;
	padding-left: 20px;
	width: 230px;
	margin-left: -230px;

	.graphics__nav-item {
		&:last-child {
			margin-bottom: 0;
		}
	}
}

.graphics__nav-item {
	margin-bottom: 15px;
	cursor: pointer;

	.paper {
		padding: 10px;
	}
}

.graphics__label {
	color: #232528;
	font-size: 20px;
	line-height: 1;
	text-align: center;
}

.graphics__preview {
	margin-top: 5px;

	img {
		width: 100%;
		height: auto;
		vertical-align: top;
	}
}
