.layout-footer {
	background-color: #232528;
	margin-top: auto;
}

.layout-footer__inner {
	min-height: 50px;
}

.nav-default {
	height: 50px;
	display: flex;
	align-items: center;
	margin: 0 -15px;
}

.nav-default__item {
	padding-left: 15px;
	padding-right: 15px; 
}