.section-login {
    background: url(/assets/images/backgrounds/background-default.jpg) 50% 50% no-repeat;
    background-size: cover;
}

.section-login__inner {
    width: 100%;
    height: 100vh;
    max-width: 30rem;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
}

.section-login__panel {
    background-color: #fff;
    padding: 30px;
}

.section-login__head {
    margin-bottom: 30px;
    width: 100%;
    max-width: 365px;
}

.section-login__logo {
    background: url(/assets/images/svg/logo.svg) 50% 50% no-repeat;
    background-size: cover;
    margin: 0 auto;
    width: 100%;
    height: 48px;
}

.section-login__note {
    text-align: right;
    color: #232528;
    font-size: 20px;
    margin-top: 10px;
}