.layout-preloader {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 1001;
	background-color: #fff;
}

@keyframes spin {
	0% { 
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
  
@keyframes spin-reverse {
	0% { 
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(-360deg);
	}
}

.loader {
	position: absolute;
	left: 50%;
	top: 50%;
	width: 140px;
	height: 140px;
	border-radius: 50%;
	animation: spin 1.6s linear infinite;
	z-index: 1;
	background: url(/assets/images/svg/circle-lg.svg) 50% 50% no-repeat;
	background-size: contain;
	margin: -70px 0 0 -70px;
}
 
.loader__item-1,
.loader__item-2 {
	position: absolute;
}

.loader__item-1 {
	background: url(/assets/images/svg/circle-md.svg) 50% 50% no-repeat;
	background-size: contain;
	animation: spin-reverse .8s linear infinite;
	width: 108px;
	height: 108px;
	z-index: 1;
	top: 16px;
	left: 16px;
}

.loader__item-2 {
	background: url(/assets/images/svg/circle-sm.svg) 50% 50% no-repeat;
	background-size: contain;
	animation: spin 1.4s linear infinite;
	width: 72px;
	height: 72px;
	z-index: 2;
	top: 34px;
	left: 34px;
}