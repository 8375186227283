*,
*::before,
*::after {
	  box-sizing: inherit;
}

:active,
:focus,
:hover {
	outline: 0;
}

html,
body {
	height: 100%;
	padding: 0;
}

html {
	box-sizing: border-box;
	-webkit-tap-highlight-color: transparent;
	overflow-y: auto;
}

body {
	font-size: 14px;
	line-height: 1.28;
	min-width: 768px;
	font-family: $font-default;
	font-weight: 400;
	color: #000;
	-webkit-font-smoothing: antialiased;
	background-color: #fafafa;
	overflow-x: hidden;
}

a {
	text-decoration: none;
	@include transition(color 0.3s ease);
	color: $color-link;

	&:hover {
		color: $color-link-hover;
		text-decoration: none;
	}
}

.link-underline {
	text-decoration-skip: ink;
}

p {
	margin: 15px 0;
}

p:empty {
	display: none;
}

input {
	-webkit-appearance: none;
	border-radius: 0;
}

.svg-store {
	display: none;
	height: 0;
	overflow: hidden;
}

.layout {
	min-height: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;
}

.layout-inner {
}

.layout-body {
	padding: 20px 0 0 0;
}

.container {
	width: 100%;
	max-width: 1280px;
	padding-left: 20px;
	padding-right: 20px;
	margin-left: auto;
	margin-right: auto;
}

.table-autoscroll {
	overflow: hidden;
	-webkit-overflow-scrolling:touch;
	overflow-x: auto;
}

.layout-inner {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

.accordion-autoscroll {
	height: 300px;
	overflow-y: auto;
	-webkit-overflow-scrolling:touch;
	padding: 2px;
}

.graph-height {
	height: 50vh;
}

@media(max-width: 1023px) {
	.accordion-autoscroll {
		height: auto;
	}
}