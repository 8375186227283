// colors
$base-color: #313131;
$base-bg: #fff;

$color-xp: #ef5945;

// link color
$color-link: #000;
$color-link-hover: $color-xp;

// fonts
$font-default: 'Roboto', sans-serif;



